import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import OTPInput from 'react-otp-input';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { tostE, tostS } from '../../Utils/Toast';
import { login, loginViaOtp, resendEmailOtp, resendPhoneOtp, sendEmailOtp, sendPhoneOtp } from '../../Services/AuthServices';
import { COUNTRY_CODE } from '../../Utils/Constants';
import { useDispatch } from 'react-redux';
import { setUser, setUserToken } from '../../Actions/ActionCreator';
import logo from './../../Assets/images/logo.png'

export default function Login() {
  const [currentTab, setCurrentTab] = useState("pass");
  const [otpRequested, setOtpRequested] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loader
  const [startTime, setStartTime] = useState(null); // Start time of update process

   useEffect(() => {
    localStorage.removeItem('merchantId');
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('merchantId');
  });
  }, []);

  
  const loginValidation = Yup.object().shape({
    emailPhone:Yup.string()
    .trim() // Trim leading and trailing white spaces
    .required('Please enter phone number/email')
    .matches(/^(?:\d{10}|[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, "Please enter a valid Phone number/email"),
    password:Yup.string().when("checkPass", {
      is:()=>(currentTab==="pass"),
      then : ()=>(Yup.string().required('Please enter password'))
    }),
    otp:Yup.string().when("checkOTP", {
      is:()=>(currentTab==="otp" && otpRequested===true),
      then :()=>(Yup.string().required('Please enter otp'))
    })
  })
  const formik = useFormik({
    enableReinitialize:true,
    initialValues:{
      emailPhone:"",
      password : "",
      otp:""
    },
    validationSchema:loginValidation,
    onSubmit:async (values) => {
      
      values.emailPhone = values.emailPhone.replace(/\s/g, "");

      if(currentTab==="otp"){
        const payload = {
          emailOrPhoneNumber:values.emailPhone,
          otp:values.otp
        }
        loginViaOtp(payload).then((res)=>{
          setStartTime(new Date()); // Record start time
          setLoading(true); // Show loader before making API call
          if(res.status===200){
            const userData = res.data.data.merchantDetails;
            const userToken = res.data.data.token;
            if(userData?.phoneVerify==false){
              setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                dispatch(setUser(userData))
                navigate("/auth/phone-verify");
            }, 2000);              

            }else if(userData?.emailVerify==false){

              setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                dispatch(setUser(userData))
                navigate("/auth/email-verify");
            }, 2000);              

            }else{
              setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                dispatch(setUserToken(userData, userToken))
            }, 500);              

             
            }
          }
        }).catch((err)=>{
          if(err?.response?.data){
            setTimeout(() => {
              setLoading(false); // Hide loader after API call completes
              setStartTime(null); // Reset start time
              tostE(err?.response?.data.message);
          }, 500);
           
          }
        }).finally((err1) => {
          setTimeout(() => {
              setLoading(false); // Hide loader after API call completes
              setStartTime(null); // Reset start time
              tostE(err1?.response?.data?.message);
          }, 500);
      });     
      }else{
        
        setStartTime(new Date()); 
        setLoading(true);
        const payload = {
          emailOrPhoneNumber:values.emailPhone,
          password:values.password
        }
        login(payload).then((res)=>{            
          const userData = res.data.data;
          if(res.status===200){
            if(userData?.phoneVerify==false){
              setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                dispatch(setUser(userData))
                navigate("/auth/phone-verify");
            }, 500);

            }else if(userData?.emailVerify==false){

              setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                dispatch(setUser(userData))
                navigate("/auth/email-verify");
            }, 500);              

            }else{

              if(userData?.phoneVerify==false){
                const userToken = res.data.data.token;
                dispatch(setUserToken(userData.merchantDetails, userToken))
             }else{
              setTimeout(() => {
                setLoading(false); // Hide loader after API call completes
                setStartTime(null); // Reset start time
                const userToken = res.data.data.token;
                dispatch(setUserToken(userData.merchantDetails, userToken))
            }, 500);
             }
            }
          }
        }).catch((err)=>{
          if(err?.response?.data){
           setTimeout(() => {
              setLoading(false); // Hide loader after API call completes
              setStartTime(null); // Reset start time
              tostE(err?.response?.data?.message);
          },500);
          }
        }).finally((err1) => {
            setTimeout(() => {
              setLoading(false);
              setStartTime(null);
              tostE(err1?.response?.data?.message);
          }, 500);
      });           
      }      
    }
  });

  const { errors, values, touched, handleChange, handleBlur, handleSubmit, setValues,setErrors, setTouched } = formik;
  
  const handleSendOtp = () =>{
    values.emailPhone = values.emailPhone.replace(/\s/g, "");

    if(values.emailPhone===""){
      setTimeout(() => {
        setLoading(false); // Hide loader after API call completes
        setStartTime(null); // Reset start time
        setErrors({ ...errors , emailPhone: "Please enter a valid email"})
        setTouched({ ...touched, emailPhone : true})
    }, 500);
    
      return;
    }else if(!errors.emailPhone){
      setStartTime(new Date()); 
      setLoading(true); 
  
      if(isNaN(values.emailPhone)){
        const payload = {
          email:values.emailPhone
        }
        sendEmailOtp(payload).then((res)=>{
          setStartTime(new Date());
          setLoading(true); 
          if(res.status===200){
            setTimeout(() => {
              setLoading(false);
              setOtpRequested(true);
          }, 500);
          }
        }).catch((err)=>{
          if(err?.response?.data){
            setTimeout(() => {
              setLoading(false);
              setStartTime(null); 
              tostE(err?.response?.data?.message);
          }, 500);
          }
        }).finally((err1) => {
          setTimeout(() => {
              setLoading(false); 
              setStartTime(null); 
              tostE(err1?.response?.data?.message);
          }, 500);
      });     
      }else{
        const payload = {
          phoneNumber:values.emailPhone,
          countryCode:COUNTRY_CODE
        }
        sendPhoneOtp(payload).then((res)=>{
          setStartTime(new Date());
          setLoading(true); 
          if(res.status===200){
            setTimeout(() => {
              setLoading(false); 
              setOtpRequested(true);
          }, 1000);
          }
        }).catch((err)=>{
          if(err?.response?.data){
            tostE(err?.response?.data?.message);
          }
        })
        .finally((err1) => {
          setTimeout(() => {
              setLoading(false);
              setStartTime(null);
              tostE(err1?.response?.data?.message);
          },500);
      });
      }
    }
    
  }

  const handleResendOtp = () =>{
    setStartTime(new Date()); 
    setLoading(true); 

    values.emailPhone = values.emailPhone.replace(/\s/g, "");

    if(values.emailPhone===""){
      setErrors({ ...errors , emailPhone: "Please enter a valid email"})
      setTouched({ ...touched, emailPhone : true})
      return;
    }else if(!errors.emailPhone){
      if(isNaN(values.emailPhone)){
        const payload = {
          email:values.emailPhone
        }
        resendEmailOtp(payload).then((res)=>{
          setStartTime(new Date()); 
          setLoading(true); 
          if(res.status===200){
            setTimeout(() => {
              setLoading(false); 
              setStartTime(null);
              tostS(res?.data?.message)
          }, 500);
            
          }
        }).catch((err)=>{
          if(err?.response?.data){
            tostE(err?.response?.data?.message);
          }
        }).finally((err1) => {
          setTimeout(() => {
              setLoading(false); 
              setStartTime(null); 
              tostE(err1?.response?.data?.message);
          }, 500);
      });     
      }else{
        const payload = {
          phoneNumber:values.emailPhone,
          countryCode:COUNTRY_CODE
        }
        resendPhoneOtp(payload).then((res)=>{
          if(res.status===200){
            setTimeout(() => {
              setLoading(false); 
              setStartTime(null); 
              tostS(res?.data?.message)
          }, 1000);
          }
        }).catch((err)=>{
          if(err?.response?.data){
            tostE(err?.response?.data?.message);
          }
        }).finally((err1) => {
          setTimeout(() => {
              setLoading(false); 
              setStartTime(null);
              tostE(err1?.response?.data?.message);
          }, 500);
      });  
      }
    }
  }



  return (
    <>
{loading && (
    <div className='preload'>
        <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
    </div>
)}            
      <div className="form-head mb-4 text-center">
        <h4>Merchant Portal</h4>
        <p>Login with your existing Phone Number/Email</p>
      </div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="form-body">
            <div className="form-group mb-4">
              <label className="form-label">Phone Number/Email</label>
              <input 
                name="emailPhone"
                type="text" 
                placeholder="Enter phone number or email"                 
                className={"form-control" + (errors.emailPhone && touched.emailPhone ? " is-invalid" : "")}
                value={values.emailPhone}
                onChange={handleChange}
                onBlur={handleBlur}
                title="Phone Number/Email"
              />
              {errors.emailPhone && touched.emailPhone ? <span className="form-error">{errors.emailPhone}</span> : null}
            </div>
            <div className="line-tabs">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button className={"nav-link " + (currentTab === "otp" ? "active" : "")} onClick={() => { setCurrentTab("otp");setOtpRequested(false); }} type="button">Login using OTP</button>
                </li>
                <li className="nav-item">
                  <button className={"nav-link " + (currentTab === "pass" ? "active" : "")} onClick={() => { setCurrentTab("pass") }} type="button">Login using Password</button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                {currentTab === "otp" ?
                  otpRequested === false ?
                    <div className="tab-pane fade show active">
                      <div className="btn-otp-box d-flex justify-content-center mt-3">
                        <button type='button' className="btn btn-primary min-w" onClick={()=>{handleSendOtp()}}>
                          Send OTP
                        </button> 
                      </div>
                    </div>
                    :
                    <div className="tab-pane fade show active" id="login-tab-pane001" role="tabpanel" aria-labelledby="login-tab-01" tabindex="0">
                      <OTPInput
                          renderSeparator={false}
                          containerStyle={"otp-form d-flex mt-3"}
                          inputStyle={{ width: "100%" }}
                          placeholder='----'
                          numInputs={4}
                          value={values.otp}
                          inputType='number'
                          onChange={(otp) => { setValues({ ...values, otp: otp }) }}
                          renderInput={(props) => <input placeholder="-" class={"form-control"} {...props} />}
                      />
                      {errors.otp && touched.otp ? <span className="form-error">{errors.otp}</span> : null}
                      <div className="recive-otp form-bottom-text text-center mt-3">
                        <span>Didn’t receive OTP? <button type='button' onClick={()=>{handleResendOtp()}} className="form-link">Resend</button></span>
                      </div>
                    </div>
                  :
                  <div className="tab-pane fade show active">
                    <div className="form-group mt-3">
                      <input 
                        name="password"
                        type="password" 
                        placeholder="Enter password" 
                        className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        title="Password"
                        autoComplete='off'
                      />
                      {errors.password && touched.password ? <span className="form-error">{errors.password}</span> : null}
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="form-btn-box mt-4">
              <button type="submit" disabled={currentTab==="otp" && otpRequested===false} className="btn btn-secondry w-100">Login</button>
              <Link
                to={"/auth/forgot-password"}
                replace={true}
                className="form-link text-center d-flex justify-content-center mt-3"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </Form>
      </FormikProvider>
      <div className="form-bottom ">
        <span>
        Don't have an account?{" "}
          <Link className="form-link" to="/auth/signup">
            Sign Up
          </Link>
        </span>
      </div>
    </>
  )
}
